import React from 'react'
import HalamanRegister from '../Components/Register/HalamanRegister'

function Register() {
  return (
    <div>
        <HalamanRegister />
    </div>
  )
}

export default Register