import React from 'react';
import HalamanLogin from '../Components/Logins/HalamanLogin';

function Login() {
  return (
    <div>
        <HalamanLogin />
    </div>
  );
}

export default Login;
