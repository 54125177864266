// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Welcome": "Find the right startup, the right way",
      "SignIn": "Sign In",
      "SignUp": "Join Us",
      "Home": "Home",
      "About": "About Us",
      "English": "English",
      "Indonesian": "Indonesian",
      // Add more key-value pairs for English
    }
  },
  id: {
    translation: {
      "Welcome": "Temukan startup yang tepat, dengan cara yang tepat",
      "SignIn": "Masuk",
      "SignUp": "Bergabunglah dengan Kami",
      "Home": "Beranda",
      "About": "Tentang Kami",
      "English": "Inggris",
      "Indonesian": "Indonesia",
      // Add more key-value pairs for Indonesian
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
